import React from "react";
import styled from "@emotion/styled";

import Section from "../@narative/gatsby-theme-novela/components/Section";
import SEO from "../@narative/gatsby-theme-novela/components/SEO";
import Layout from "../@narative/gatsby-theme-novela/components/Layout";
import mediaqueries from "../@narative/gatsby-theme-novela/styles/media";


import { Template } from "@types";

const ArticlesPage: Template = ({ location, pageContext }) => {
//   const tag = pageContext.additionalContext.tag;
//   const articles = pageContext.group;

  return (
    <Layout>
      <SEO
        pathname={location.pathname}
        title={"Sorry"}
        description={"404 Page  not found"}
      />
      <Section >
          <div style={{width:"100%", height:"55vh", justifyContent:"center" , display:"flex",flexDirection:"column",alignItems:"center", padding:"5%"}}>
            <HeroHeading>404 page not  found.</HeroHeading>
            <Subheading>I am not good with advice, can I interest you in a sarcastic 404 message?</Subheading>
          </div>
      </Section>
      <AuthorsGradient />
    </Layout>
  );
}

export default ArticlesPage;

const AuthorsGradient = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 590px;
  z-index: 0;
  pointer-events: none;
  background: ${p => p.theme.colors.gradient};
  transition: ${p => p.theme.colorModeTransition};
`;

const HeroHeading = styled.div`
  font-style: normal;
  text-shadow:2px 2px 2px rgba(10,10,10,0.4);
  font-weight: 200;
  font-family: "Mad Hacker", georgia, serif;
  font-size: 52px;
  line-height: 1.15;
  z-index:100;
  align-content: center;
  color: ${p => p.theme.colors.primary};

  .realName {
    text-decoration: line-through;
  }

  .subtext {
    font-size: 24px;
    color: ${p => p.theme.colors.grey};
    line-height: 0.2 !important;
    font-family: "Mad Hacker Italic", georgia, serif;
  }

  a {
    color: ${p => p.theme.colors.accent};
  }

  ${mediaqueries.desktop`
    font-size: 38px
  `}

  ${mediaqueries.phablet`
    font-size: 32px;
  `}
`;


const Subheading = styled.p`
  margin: 0 auto;
  max-width: 450px;
  color: ${p => p.theme.colors.grey};
  font-size: 18px;
  font-family: ${p => p.theme.fonts.sansSerif};
  line-height: 1.4;
  text-align: center;

  ${mediaqueries.phablet`
    font-size: 14px;
  `}
`;

